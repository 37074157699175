import React, { useEffect, useState } from "react";
import RemoveFromCart from "../assets/images/RemoveFromCart.svg";
import CartIcon from "../assets/images/CartIcon.png";
import CustomerDetails from "../components/CustomerDetails";

const Cart = ({
  cartItems,
  increaseQuantity,
  decreaseQuantity,
  removeFromCart,
  // totalPrice,
  setIsCartOpen,
  isMobile,
  // theme,
}) => {
  const [orderRequest, setOrderRequest] = useState("");

  // Function to calculate tax for an item
  const calculateItemTax = (item, price) => {
    const mainItemTaxPercentage = item.tax?.[0]?.custom_tax_percentage
      ? parseFloat(item.tax[0].custom_tax_percentage)
      : 0;
    const itemTaxPercentage = item.item_tax?.[0]?.custom_tax_percentage
      ? parseFloat(item.item_tax[0].custom_tax_percentage)
      : 0;
    const effectiveTaxPercentage = Math.max(
      mainItemTaxPercentage,
      itemTaxPercentage
    );

    return (price * effectiveTaxPercentage) / 100;
  };

  // Calculate subtotal and total tax including related and subrelated items
  const calculateSubtotalAndTax = () => {
    let subtotal = 0;
    let totalTax = 0;

    cartItems.forEach((item) => {
      let itemTotalPrice = item.product_price * item.quantity;
      let itemTax = calculateItemTax(item, item.product_price) * item.quantity;

      const selectedAttributes = item.selectedAttributes;
      const selectedComboItems = item.selectedComboItems;

      if (selectedAttributes) {
        Object.values(selectedAttributes).forEach((attributeItemArray) => {
          if (Array.isArray(attributeItemArray)) {
            attributeItemArray
              .filter((option) => option !== null)
              .forEach((option) => {
                if (option.price) {
                  itemTotalPrice += parseFloat(option.price) * item.quantity;
                  itemTax +=
                    calculateItemTax(option, parseFloat(option.price)) *
                    item.quantity;
                }
              });
          }
        });
      }

      if (selectedComboItems) {
        Object.values(selectedComboItems).forEach((comboItemArray) => {
          if (Array.isArray(comboItemArray)) {
            comboItemArray
              .filter((comboItem) => comboItem !== null)
              .forEach((comboItem) => {
                if (comboItem.price) {
                  itemTotalPrice += parseFloat(comboItem.price) * item.quantity;
                  itemTax +=
                    calculateItemTax(comboItem, parseFloat(comboItem.price)) *
                    item.quantity;
                }
              });
          }
        });
      }
      subtotal += itemTotalPrice;
      totalTax += itemTax;
    });

    return { subtotal, totalTax };
  };

  const { subtotal, totalTax } = calculateSubtotalAndTax();
  const totalWithTax = subtotal + totalTax;

  useEffect(() => {
    if (cartItems.length === 0) {
      setOrderRequest("");
    }
  }, [cartItems]);

  useEffect(() => {
    localStorage.setItem("subtotal", subtotal.toFixed(2));
    localStorage.setItem("totalTax", totalTax.toFixed(2));
  }, [subtotal, totalTax]);

  return (
    <div
      className={`cart-container ${isMobile ? "cart-popup" : "cart-sidebar"}`}
    >
      <div className="cart-wrap">
        <div className="d-flex justify-content-between cart-head">
          <h2 className="cart-heading">Basket</h2>
          {cartItems.length > 0 ? (
            <span className="cart-item-count-text">
              {cartItems.length === 1
                ? `${cartItems.length} Item`
                : `${cartItems.length} Items`}
            </span>
          ) : (
            <span className="cart-item-count-text">0 Item</span>
          )}

          {isMobile && (
            <button
              className="btn btn-link text-danger"
              onClick={() => setIsCartOpen(false)}
            >
              Close
            </button>
          )}
        </div>
        <div className="cart-content">
          {cartItems.length === 0 ? (
            <div className="empty-cart">
              <img
                src={CartIcon}
                className="d-flex justify-content-center p-2 mx-auto"
                alt=""
              />
              <p className="d-flex justify-content-center">No Item In Basket</p>
            </div>
          ) : (
            <>
              <div
                style={{
                  maxHeight: cartItems.length > 4 ? "260px" : "auto",
                  overflowY: cartItems.length > 4 ? "scroll" : "visible",
                }}
                className="scrollable-content"
              >
                {cartItems.map((item, index) => {
                  const itemTotalPrice = item.product_price;
                  const selectedAttributes = item.selectedAttributes;
                  const selectedComboItems = item.selectedComboItems;
                  console.log(
                    selectedComboItems,
                    "selected items selected in basket"
                  );
                  let totalItemPrice = itemTotalPrice * item.quantity;

                  if (selectedAttributes) {
                    Object.values(selectedAttributes).forEach(
                      (attributeItemArray) => {
                        if (Array.isArray(attributeItemArray)) {
                          attributeItemArray.forEach((option) => {
                            if (option && option.price) {
                              totalItemPrice += parseFloat(option.price);
                            }
                          });
                        }
                      }
                    );
                  }

                  if (selectedComboItems) {
                    Object.values(selectedComboItems).forEach(
                      (comboItemArray) => {
                        if (Array.isArray(comboItemArray)) {
                          comboItemArray.forEach((comboItem) => {
                            if (comboItem && comboItem.price) {
                              totalItemPrice += parseFloat(comboItem.price);
                            }
                          });
                        }
                      }
                    );
                  }

                  return (
                    <div
                      key={index}
                      className="p-2 border-bottom align-items-center justify-content-between cart-body"
                    >
                      <div className="d-flex justify-content-between">
                        <div>
                          <p>
                            {item.name} - <span className="text-black">£</span>
                            {(itemTotalPrice * item.quantity).toFixed(2)}
                          </p>
                          {selectedAttributes && (
                            <div className="selected-attribute">
                              {Object.values(selectedAttributes).map(
                                (attributeItemArray, attributeIndex) => (
                                  <div key={attributeIndex}>
                                    {Array.isArray(attributeItemArray) &&
                                      attributeItemArray.map(
                                        (option, attributeItemIndex) => (
                                          <p key={attributeItemIndex}>
                                            {option.item_name || option.item} -
                                            £{option.price}
                                          </p>
                                        )
                                      )}
                                  </div>
                                )
                              )}
                            </div>
                          )}
                          {selectedComboItems && (
                            <div className="selected-attribute">
                              {Object.values(selectedComboItems).map(
                                (comboItemArray, comboIndex) => (
                                  <div key={comboIndex}>
                                    {Array.isArray(comboItemArray) &&
                                      comboItemArray.map(
                                        (comboItem, comboIndex) => (
                                          <p key={comboIndex}>
                                            {comboItem.item_name ||
                                              comboItem.item}{" "}
                                            - £{comboItem.price}
                                          </p>
                                        )
                                      )}
                                  </div>
                                )
                              )}
                            </div>
                          )}
                        </div>

                        <div className="d-flex align-items-center justify-content-between">
                          <div className="border quantity-control">
                            <span
                              className=""
                              onClick={() => decreaseQuantity(item.id)}
                            >
                              -
                            </span>
                            <span className="mx-2">{item.quantity}</span>
                            <span
                              className="mr-1"
                              onClick={() => increaseQuantity(item.id)}
                            >
                              +
                            </span>
                          </div>
                          <img
                            src={RemoveFromCart}
                            alt=""
                            onClick={() => removeFromCart(item.id)}
                          ></img>
                        </div>
                      </div>
                      <div className="d-flex fw-semibold cart-item-price">
                        <span className="text-black">£</span>
                        {totalItemPrice.toFixed(2)}
                      </div>

                      {/* {item.relatedItems && item.relatedItems.length > 0 && (
                        <div className="related-items">
                          {item.relatedItems.map((relatedItem, relIndex) => {
                            const relatedItemTotalPrice =
                              relatedItem.product_price;

                            return (
                              <div
                                key={relIndex}
                                className="related-item p-1 border-bottom"
                              >
                                <div className="d-flex justify-content-between">
                                  <p>{relatedItem.name}</p>
                                  <div className="d-flex align-items-center justify-content-between">
                                    <div className="border quantity-control">
                                      <a
                                        className=""
                                        onClick={() =>
                                          decreaseQuantity(relatedItem.id)
                                        }
                                      >
                                        -
                                      </a>
                                      <span className="mx-2">
                                        {relatedItem.quantity}
                                      </span>
                                      <a
                                        className="mr-1"
                                        onClick={() =>
                                          increaseQuantity(relatedItem.id)
                                        }
                                      >
                                        +
                                      </a>
                                    </div>
                                    <img
                                      src={RemoveFromCart}
                                      alt=""
                                      onClick={() => removeFromCart(relIndex)}
                                    ></img>
                                  </div>
                                </div>
                                <div className="d-flex fw-semibold cart-item-price">
                                  <span className="text-black">£</span>
                                  {(
                                    relatedItemTotalPrice * relatedItem.quantity
                                  ).toFixed(2)}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      )} */}

                      {/* {item.subRelatedItems &&
                        item.subRelatedItems.length > 0 && (
                          <div className="subrelated-items">
                            {item.subRelatedItems.map(
                              (subRelatedItem, subRelIndex) => {
                                const subRelatedItemTotalPrice =
                                  subRelatedItem.product_price;

                                return (
                                  <div
                                    key={subRelIndex}
                                    className="subrelated-item p-1 border-bottom"
                                  >
                                    <div className="d-flex justify-content-between">
                                      <p>{subRelatedItem.name}</p>
                                      <div className="d-flex align-items-center justify-content-between">
                                        <div className="border quantity-control">
                                          <a
                                            className=""
                                            onClick={() =>
                                              decreaseQuantity(
                                                subRelatedItem.id
                                              )
                                            }
                                          >
                                            -
                                          </a>
                                          <span className="mx-2">
                                            {subRelatedItem.quantity}
                                          </span>
                                          <a
                                            className="mr-1"
                                            onClick={() =>
                                              increaseQuantity(
                                                subRelatedItem.id
                                              )
                                            }
                                          >
                                            +
                                          </a>
                                        </div>
                                        <img
                                          src={RemoveFromCart}
                                          alt=""
                                          onClick={() =>
                                            removeFromCart(subRelIndex)
                                          }
                                        ></img>
                                      </div>
                                    </div>
                                    <div className="d-flex fw-semibold cart-item-price">
                                      <span className="text-black">£</span>
                                      {(
                                        subRelatedItemTotalPrice *
                                        subRelatedItem.quantity
                                      ).toFixed(2)}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        )} */}
                    </div>
                  );
                })}
              </div>
              <div className="py-3 border-top order-note">
                <h6>Order Request</h6>
                <input
                  type="text"
                  placeholder="Enter your order request"
                  value={orderRequest}
                  onChange={(e) => setOrderRequest(e.target.value)}
                  className="form-control p-0 order-note-field"
                  style={{ border: "none", boxShadow: "none" }}
                />
              </div>
              <div className="py-3 d-flex flex-column gap-2 border-top">
                <div className="cart-price-row">
                  <p className="fw-semibold text-black">Subtotal</p>
                  <p className="fw-semibold d-flex">
                    <span>£</span>
                    {subtotal.toFixed(2)}
                  </p>
                </div>
                <div className="cart-price-row">
                  <p className="text-black">VAT</p>
                  <p className="d-flex">
                    <span>£</span>
                    {totalTax.toFixed(2)}
                  </p>
                </div>
              </div>
              <div className="py-3 cart-total-row">
                <p className="text-black">Total</p>
                <p className="d-flex">
                  <span>£</span>
                  {totalWithTax.toFixed(2)}
                </p>
              </div>
              <div className="checkout-btn-wrap">
                <CustomerDetails
                  cartItems={cartItems}
                  totalPrice={totalWithTax.toFixed(2)}
                  orderRequest={orderRequest}
                />
              </div>
            </>
          )}
        </div>
      </div>
      {isMobile && (
        <div
          className="cart-popup-overlay"
          onClick={() => setIsCartOpen(false)}
        ></div>
      )}
    </div>
  );
};

export default Cart;
