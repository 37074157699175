import APIs from "../../api/index";
import axiosInstance from "../../translator";

export const getApitoken = async () => {
  try {
    const res = axiosInstance.get(APIs.token);
    return res;
  } catch (error) {
    console.log("Error while getting the token", error);
    return error.response;
  }
};
// export const getCustomerDetails = async (domainUrl) => {
//   try {
//     const response = await axiosInstance.post(
//       "https://test-kleogetpos-admindev.frappe.cloud/api/method/getposadmin.api.get_customer_details",
//       {
//         domain_url: domainUrl,
//       }
//     );
//     console.log("Customer details", response);
//     return response;
//   } catch (error) {
//     console.error("Error fetching customer details:", error);
//     return { status: error.response?.status || 500, data: error.message };
//   }
// };

export const getCustomerDetails = async (domainUrl) => {
  try {
    const response = await axiosInstance.get(
      "https://kleocontrolhub.kleopos.com/api/method/getposadmin.api.get_customer_details",
      {
        params: {
          domain_url: domainUrl,
        },
      }
    );
    console.log("Customer details", response);
    return response;
  } catch (error) {
    console.error("Error fetching customer details:", error);
    return { status: error.response?.status || 500, data: error.message };
  }
};

export const getLocation = async (searchLocation = "") => {
  try {
    const url = `${APIs.getLocation}?search_location=${searchLocation}`;
    const res = await axiosInstance.get(url);
    return res;
  } catch (error) {
    console.log("Error getting Location", error);
  }
};

export const getRestaurantDetailByLocation = async (custom_location = "") => {
  try {
    const url = `${APIs.getLocation}?custom_location=${custom_location}`;
    const res = await axiosInstance.get(url);
    return res.data;
  } catch (error) {
    console.log("Error getting Location", error);
    throw error;
  }
};

export async function fetchStoreDetails(location) {
  const response = await fetch(
    `${APIs.getLocation}?custom_location=${location}`
  );
  const data = await response.json();
  return data.message;
}

export const fetchSelectedStoreDetails = async (id) => {
  try {
    const response = await axiosInstance.get(`${APIs.getLocation}/${id}`);
    console.log("API Response for Store Details:", response.data);
    return response.data;
  } catch (error) {
    console.error("Error fetching store details:", error);
    return [];
  }
};

export const getrestaurantByLocation = async (payload) => {
  try {
    const res = await axiosInstance.post(APIs.getLocation, payload);
    return res;
  } catch (error) {
    console.log("Error getting Location", error);
  }
};

export const getItemsGroup = async (apiToken, name) => {
  try {
    const res = await axiosInstance.get(APIs.getItems, {
      headers: {
        Authorization: `${apiToken}`,
      },
      params: {
        cost_center: name,
      },
    });
    return res;
  } catch (error) {
    console.log("Error getting Items", error);
  }
};

export const getItemsGroupDetails = async (apiToken, name) => {
  try {
    const res = await axiosInstance.get(APIs.getItemsDetail, {
      headers: {
        Authorization: `${apiToken}`,
      },
      params: {
        cost_center: name,
      },
    });
    return res;
  } catch (error) {
    console.log("Error getting Items", error);
  }
};

export const getPaymentList = async () => {
  try {
    const res = await axiosInstance.get(APIs.getPaymentList);
    return res.data.payment_list;
  } catch (error) {
    throw new Error("Error fetching payment list: " + error.message);
  }
};

export const getItemDetails = async (itemId, apiToken) => {
  try {
    const res = await axiosInstance.get(APIs.getItems, {
      headers: {
        Authorization: apiToken,
      },
    });

    if (res.status === 200) {
      const items = res.data.message.flatMap((group) => group.items);
      const item = items.find((item) => item.id === itemId);

      if (item) {
        return { status: 200, data: { message: { data: item } } };
      } else {
        return { status: 404, error: "Item not found" };
      }
    } else {
      return { status: res.status, error: res.statusText };
    }
  } catch (error) {
    return { status: 500, error: error.message };
  }
};

export const fetchItemAttributes = async (item_code) => {
  try {
    const response = await axiosInstance.get(
      `${APIs.getItems}?item_code=${item_code}`
    );
    if (response.status === 200) {
      const data = response.data;
      //console.log(data.message, "JSON Attributes response item code");

      const itemGroup = data.message.find((group) =>
        group.items.some((item) => item.id === item_code)
      );
      if (itemGroup) {
        const item = itemGroup.items.find((item) => item.id === item_code);
        if (item) {
          return item.attributes;
        }
      }
      return [];
    } else {
      console.error("Failed to fetch item attributes:", response.statusText);
      return [];
    }
  } catch (error) {
    console.error("Error fetching item attributes:", error);
    return [];
  }
};

export const fetchItemComboItems = async (item_code) => {
  try {
    const response = await axiosInstance.get(
      `${APIs.getItems}?item_code=${item_code}`
    );
    if (response.status === 200) {
      const data = response.data;
      //console.log(data.message, "JSON Combo Item response item code");
 
      const itemGroup = data.message.find((group) =>
        group.items.some((item) => item.id === item_code)
      );
      if (itemGroup) {
        const item = itemGroup.items.find((item) => item.id === item_code);
        if (item) {
          return item.combo_items;
        }
      }
      return [];
    } else {
      console.error("Failed to fetch item Combo items:", response.statusText);
      return [];
    }
  } catch (error) {
    console.error("Error fetching item Combo items:", error);
    return [];
  }
};