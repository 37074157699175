import APIs from "../../api/index";
import axiosInstance from "../../translator";

export const getOrderDetails = async (orderID, apiToken) => {
    try {
        const res = await axiosInstance.get(`${APIs.getOrderDetails}?order_id=${orderID}`, {
          headers: {
            Authorization: `${apiToken}`
          },
        });
        console.log(res, "Order details retrieved successfully");
        return res;
      } catch (error) {
        console.log("Error while getting the Order Details", error);
        return error.response;
      }
    };