const APIs = {
    token:'/api/method/getpos.getpos.api.get_web_theme_settings',
    getCustomerDetails:'api/method/getposadmin.api.get_customer_details',
    getLocation:'/api/method/getpos.getpos.api.get_location',
    getItems:'/api/method/getpos.custom_api.item_variant_api.get_items?source=WEB',
    getItemsDetail:'/api/method/getpos.custom_api.item_variant_api.get_items',
    kitchenKds: '/api/method/getpos.getpos.api.get_kitchen_kds',
    cds:'/api/method/getpos.getpos.api.update_status',
    createOrder:'/api/method/getpos.getpos.api.create_sales_order_kiosk',
    getPayment: '/api/method/getpos.getpos.api.payment_request',
    getPaymentList: '/api/method/getpos.getpos.api.payment_request',
    transaction:'/api/method/getpos.getpos.api.transaction_status',
    createInvoice:'/api/method/getpos.getpos.api.create_web_sales_invoice',
    getOrderDetails:'/api/method/getpos.getpos.api.get_sales_order_item_details', 
}
export default APIs;