import React, { useContext, useEffect, useState } from "react";
import { Button, Layout, Spin, notification } from "antd";
import Footer from "../components/Footer";
import webLogo from "../assets/images/webLogo.svg";
import OrderSuccessImage from "../assets/images/ordersuccess.png";
import OrderSuccessVideo from "../assets/videos/ordersuccess.mp4";
import { getApitoken, getCustomerDetails } from "../module/landingScreen";
import { createInvoice, transactionSuccess } from "../module/payment";
import { useNavigate } from "react-router-dom";
import { getCartDetails, getOrderDetails } from "../module/CartDetails";
import Location from "../assets/images/locationpointer.png";
import { ThemeContext } from "../components/ThemeContext";

const { Header } = Layout;

function OrderSuccesspage() {
  const customerDetails = JSON.parse(localStorage.getItem("customerDetails"));
  const totalPrice = localStorage.getItem("totalPrice");
  const subtotal = localStorage.getItem("subtotal");
  const totalTax = localStorage.getItem("totalTax");
  const orderId = localStorage.getItem("OrderID");
  const TransactionDate = localStorage.getItem("TransactionDate"); 
  const searchParams = new URLSearchParams(window.location.search);
  const tValue = searchParams.get("t");

  const navigate = useNavigate();
  const { theme } = useContext(ThemeContext);

  const [apiToken, setApiToken] = useState([]);
  const [showPage, setShowPage] = useState(false);
  const [loading, setLoading] = useState(true);
  const [orderDetail, setOrderDetail] = useState([]);
  const [themeSettings, setThemeSettings] = useState([]);
  const [order, setOrder] = useState([]);
  const [customerData, setCustomerData] = useState([]);
  const [themeUrl, setThemeUrl] = useState([]);

  const fetchToken = async () => {
    try {
      const res = await getApitoken();
      if (res.status === 200) {
        const data = res.data.message.data;
        setApiToken(data.api_token);
        setThemeUrl(data.url);
        setThemeSettings(data);

        // const response = await getCustomerDetails(data.url);
        // if (response.status === 200) {
        //   setCustomerData(response.data.message);
        //   executeTransaction(response.data.message, data.api_token);
        // } else {
        //   console.log("Error in getting the API Customer Details");
        // }
      } else {
        console.log("Error in getting the API Token");
      }
    } catch (error) {
      console.error("Error fetching token:", error);
    }
  };

  const handleCustomerDetails = async (themeUrl) => {
    const response = await getCustomerDetails(themeUrl);
    if (response.status === 200) {
      setCustomerData(response.data.message);
      executeTransaction(response.data.message, apiToken);
      console.log("Customer Details", response.data.message);
    } else {
      console.log("Error in getting the API Customer Details");
    }
  };

  useEffect(() => {
    if (themeUrl) {
      handleCustomerDetails(themeUrl);
    }
  }, [themeUrl]);

  const orderDetails = async (apiToken) => {
    try {
      const cartres = await getOrderDetails(orderId, apiToken);
      if (cartres.status === 200) {
        setOrderDetail(cartres.data.message.item_details);
        console.log(cartres.data.message.item_details,"check")
        localStorage.removeItem("cartItems");
        setOrder(cartres.data.message);
      } else {
        console.error("Error in getting the Order Details");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error fetching token:", error);
      setLoading(false);
    }
  };

  const executeTransaction = async (customerData, apiToken) => {
    try {
      const paymentList = {
        auth_token_url: customerData.authtoken_url,
        client_id: customerData.client_id,
        client_secret: customerData.client_secret,
        base_payment_url: customerData.base_payment_url,
        customer_email: customerDetails.email,
        customer_name: customerDetails.customerName,
        customer_phone: customerDetails.phone,
        country_code: "GB",
        request_lang: "en-GB",
        amount: totalPrice * 100,
        customer_trans: "payment description",
        checkout_url: customerData.checkout_url,
        source_code: customerData.source_code,
        merchant_id: customerData.merchant_id,
        isv_percentage: customerData.isv_percentage,
      };
      const transactionPayload = {
        merchant_id: customerData.merchant_id,
        transaction_id: tValue,
        payment_list: paymentList,
      };

      const transactionRes = await transactionSuccess(
        transactionPayload,
        apiToken
      );

      if (
        transactionRes.status === 200 &&
        transactionRes.data.message.statusId === "F"
      ) {
        setShowPage(true);
        const payload2 = {
          message: {
            status: transactionRes.data.message.statusId,
            order_id: orderId,
            transaction_date: TransactionDate,
          },
        };
        try {
          const Invoice = await createInvoice(payload2, apiToken);
          if (Invoice.status === 200) {
            notification.success({
              message: "Invoice Created Successfully",
            });
          } else {
            notification.error({
              message: "Invoice Creation Failed",
              description: `Failed to create invoice: ${
                Invoice.data.message || "Unknown error"
              }`,
            });
          }
        } catch (invoiceError) {
          notification.error({
            message: "Invoice Creation Error",
            description: `Failed to create invoice: ${
              invoiceError.message || "Unknown error"
            }`,
          });
        }
        orderDetails(apiToken);
      } else {
        notification.error({
          message: "Transaction Failed",
          description: `Transaction failed: ${
            transactionRes.data.message || "Unknown error"
          }`,
        });
      }
    } catch (error) {
      console.error("Error executing transaction:", error);
      notification.error({
        message: "Transaction Execution Error",
        description: `Error executing transaction: ${
          error.message || "Unknown error"
        }`,
      });
    } finally {
      setLoading(false);
    }
  };

  const extractedAddress =
    order && order.address ? order.address.split(",")[0] : "";

  useEffect(() => {
    fetchToken();
  }, []);

  if (loading) {
    return (
      <div className="payment-spinner">
        <Spin size="large" />
        <p>Payment in progress...</p>
      </div>
    );
  }

  return showPage ? (
    <div>
      <Header className="header">
        <a href="/">
          <img
            src={`${themeSettings?.web_logo_image}`}
            alt="Logo"
            style={{ height: "50px", marginRight: "10px" }}
          />
        </a>
      </Header>
      <div className="container-fluid order-success px-4 pb-5 orderSuccess-cart">
        <div className="row">
          <div className="col-md-8 orderSuccesscontent">
            <h1>Thank you for choosing {extractedAddress}!</h1>
            <p>
              Your order is being prepared and will be ready in just a few
              minutes!
            </p>
            <div className="d-flex">
              <span style={{ paddingRight: "4px" }}>
                <img src={Location}></img>
              </span>
              <p className="address-text">{order.address}</p>
            </div>
            <video
              autoPlay
              loop
              muted
              className="w-100"
              style={{ height: "260px" }}
            >
              <source src={OrderSuccessVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <Button
              className="d-flex justify-content-center mx-auto btn-primary continue-btn"
              onClick={() => navigate("/")}
            >
              Continue Ordering
            </Button>
          </div>
          <div className="col-md-4">
            <div className="basket rounded-3 ">
              <div className="d-flex justify-content-between cart-head">
                <h2>Basket</h2>
                <p className="Total-items" style={{ color: theme.textColor }}>
                  {orderDetail.length} &nbsp;
                  {orderDetail.length === 1 ? "Item" : "Items"}
                </p>
              </div>
              <div className="cart-body">
                <p className="order-id">Order ID #{orderId}</p>
                <div className="cart-item-list  scrollable-content">
                  {" "}
                  {orderDetail.map((item, index) => (
                    <div
                      key={index}
                      className="d-flex justify-content-between align-items-start justify-content-between cart-item"
                    >
                      <div className="cart-item-name-price">
                        <p>{item.item_name}</p>
                        <p className="d-flex cart-item-price">
                          £
                          <span className="thank-item-price">
                            {item.price.toFixed(2)}
                          </span>
                        </p>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="w-100 cart-qty">
                          <span className="mx-2">{item.qty}</span>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
                {order.order_request && (
                  <div className="order-note">
                    <h4>Order Request</h4>
                    <p>{order.order_request}</p>
                  </div>
                )}
                <div className="d-flex justify-content-between cart-total-paid mt-1">
                  <h6>Subtotal</h6>
                  <p className="cart-amount">
                    £ <span style={{ color: theme.textColor }}>{subtotal}</span>
                  </p>
                </div>
                <div className="d-flex justify-content-between cart-tax">
                  <p style={{ fontSize: "14px" }}>VAT</p>
                  <p className="cart-amount d-flex">
                    £
                    <p style={{ color: theme.textColor, marginLeft: "5px" }}>
                      {totalTax}
                    </p>
                  </p>
                </div>
                <div className="d-flex justify-content-between cart-total-paid">
                  <p>Total</p>
                  <p className="cart-amount">
                    £ <span>{totalPrice}</span>
                  </p>
                </div>
              </div>
            </div>
            <div className="border rounded p-3 mt-3">
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "13px",
                  fontWeight: "500",
                }}
              >
                <span>Estimated time</span>
                <span>{order.estimated_time} min</span>{" "}
              </span>
              <span
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontSize: "14px",
                }}
              >
                <p>Service</p>
                <p style={{ color: theme.textColor }}>Take away</p>
              </span>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  ) : (
    <div className="payment-spinner">
      <Spin size="large" />
      <p style={{ display: "contents" }}>Payment in progress...</p>
    </div>
  );
}

export default OrderSuccesspage;
