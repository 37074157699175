// WebLanding.js
import React, { useEffect, useState } from "react";
import Header from "../components/WebHeader";
import Footer from "../components/Footer";
import { useLocation } from "../../src/components/LocationContext"; 
import { getApitoken, getrestaurantByLocation } from "../module/landingScreen";
import Outletcards from "../components/Outletcards";
import Banner from "../assets/images/backgroundimage.png";

const WebLanding = () => {
  const { selectedLocation } = useLocation();
  const [outlets, setOutlets] = useState([]);
  const [apiToken, setApiToken] = useState([]);



  const getAPIToken = async () => {
    try {
      const res = await getApitoken();
      if (res.status === 200) {
        const apiToken = res.data.message.data;
        setApiToken(apiToken);
      } else {
        console.error("Failed to fetch API token:", res.status);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getAPIToken();
  }, []);

  useEffect(() => {
    if (selectedLocation) {
      GetRestaurantsByLocation();
    }
  }, [selectedLocation]);

  const GetRestaurantsByLocation = async () => {
    const payload = {
      custom_location: selectedLocation,
    };
    try {
      const res = await getrestaurantByLocation(payload);
      if (res.status === 200) {
        setOutlets(res.data.message);
      }
    } catch (error) {
      console.error("Error fetching restaurants:", error);
    }
  };

  useEffect(() => {
    if (outlets.length > 0) {
      window.scrollTo({
        top: document.documentElement.scrollHeight * 0.4,
        behavior: 'smooth'
      });
    }
  }, [outlets]);

  return (
    <>
      <div className="hero-section">
        <img src={`${apiToken?.web_banner_image}`} alt="Banner" />
      </div>
      {selectedLocation ? (
        <div className="container-fluid store-list">
          <div className="store-list-head">
            <h3>Store Near You</h3>
          </div>
          <div className="row store-list-grid">
            {outlets.map((outlet, index) => (
              // <div key={index} className="col-xl-4 col-lg-4 col-md-4 col-sm-6 mb-5">
              <div key={index} className="store-box">
                <Outletcards outlet={outlet} />
              </div>
            ))}
          </div>
        </div>
      ) : (
        <div className="Location">
          {/* <h1 className="text-center pt-5 custom-color">
            Please Select Your City
          </h1> */}
        </div>
      )}
      <Footer />
    </>
  );
};

export default WebLanding;
